import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-regular-svg-icons"

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              id
              fixed(grayscale: true, height: 35) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => (
        <footer
          style={{ color: `var(--grayonWhite)` }}
          className="flex-column py-5 px-2 md:px-8 lg:p-8 text-xs font-thin"
        >
          <div className="flex flex-wrap w-full m-auto flex-row justify-evenly mb-4">
            <div className="flex flex-col">
              <Link to="/about">About us</Link>
              <Link to="/blog">Blog</Link>
              <Link to="/wow">World of Warcraft</Link>
              <Link to="/wildstar">Wildstar</Link>
            </div>
            <div className="flex flex-col">
              <Link to="/legal-notice">Legal Notice</Link>
              <Link to="/privacy">Privacy</Link>
              <Link to="/legal">Legal </Link>
            </div>
          </div>
          <div className="text-center mt-1">
            <p className="mx-auto w-full lg:w-1/2 text-xs">
              Blizzard, Battle.net, World of Warcraft, and all associated logos
              and designs are trademarks or registered trademarks of Blizzard
              Entertainment. All other trademarks are the property of their
              respective owners. This site is not affiliated with Blizzard
              Entertainment or any of their family of sites.
              <br />
              NCSOFT, the interlocking NC logo, Carbine, WildStar, and all
              associated logos and designs are trademarks or registered
              trademarks of NCSOFT Corporation. All other trademarks are the
              property of their respective owners. This site is not affiliated
              with NCSOFT or any of their family of sites.
            </p>{" "}
            <div className="flex justify-center">
              <Img fixed={data.logo.childImageSharp.fixed} />
            </div>
            Crafted with{" "}
            <FontAwesomeIcon
              style={{ width: `13.5px`, height: `13.5px` }}
              className="text-red-600"
              icon={faHeart}
            />{" "}
            by the very handsome people of Arctium.
          </div>
          <div className="text-center mt-0">&copy; 2020 Arctium</div>
        </footer>
      )}
    />
  )
}
