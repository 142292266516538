import PropTypes from "prop-types"
import React, { useState } from "react"
import ArctiumLogo from "../image"
import { Link } from "gatsby"
const Header = ({ siteTitle }) => {
  const [isExpanded, toggleExpansion] = useState(false)
  return (
    <nav class="flex items-center justify-between flex-wrap p-2 md:p-8">
      <div class="flex items-center flex-shrink-0 mr-6">
        <ArctiumLogo />
        <span class="ml-5 font-semibold text-2xl">
          <Link to="/">{siteTitle}</Link>
        </span>
      </div>
      <div class="block lg:hidden">
        <button
          onClick={() => toggleExpansion(!isExpanded)}
          class="flex items-center px-3 py-2 border rounded text-gray-600 border-gray-600 hover:text-gray-800 hover:border-gray-800"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={`${
          isExpanded ? `block` : `hidden`
        } w-full block flex-grow lg:flex lg:items-center lg:w-auto font-thin`}
      >
        <div class="lg:flex-grow tracking-widest">
          <span class="block mt-4 lg:inline-block lg:mt-0 mr-4 mx-3">
            <Link to="/blog">Blog</Link>
          </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://files.arctium.io/s/YkJxiesNXSMCWr8"
            class="block mt-4 lg:inline-block lg:mt-0 mx-3"
          >
            Downloads
          </a>
          <span class="block mt-4 lg:inline-block lg:mt-0 mr-4 mx-3">
            <Link to="/app">Arctium App</Link>
          </span>
          <span class="block mt-4 lg:inline-block lg:mt-0 mr-4 mx-3">
            <Link to="/wow">World of Warcraft</Link>
          </span>
          <span class="block mt-4 lg:inline-block lg:mt-0 mx-3">
            <Link to="/wildstar">Wildstar</Link>
          </span>
        </div>
        <div>
          <span class="inline-block items-center mt-4 lg:inline-block lg:mt-0 mx-3 bg-green-500 px-3 py-1 text-white rounded-full ">
            <img
              style={{ bottom: `2px` }}
              className="h-4 inline mb-0 mr-1 relative"
              src="https://storage.ko-fi.com/cdn/cup-border.png"
              alt="kofi logo"
            />
            <a
              style={{ fontSize: `16px` }}
              className=""
              target="_blank"
              rel="noreferrer"
              href="https://ko-fi.com/arctium"
            >
              Donate on Ko-fi
            </a>
          </span>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
